import React from 'react';
import { Link, navigate, useStaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import * as constants from '../constants';
import TextBalancer from '../components/text-balancer';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { useIcons } from '../hooks/icons';
import { useFlags } from '../hooks/flags';
import { createCityUrl, createStateUrl } from '../lib/urls';
import { Location, Contract } from '../interfaces';
import ShortDate from '../components/date';
import {
  AirtableLocation,
  AirtableIssueType,
  mapLocation,
  mapIssueType,
} from '../airtable-mapping';
import { TakeActionLink } from '../components/buttons';
import SVG from 'react-inlinesvg';

const FEATURED_CONTRACTS = [
  '458', // portland
  '1029', // phoenix
  '968', // louisville
  '649', // san antonio
];

const HomePage: React.FC<{
  pageContext: {
    airtableStates: AirtableLocation[];
    airtableCities: AirtableLocation[];
    airtableIssueTypes: AirtableIssueType[];
  };
}> = ({ pageContext }) => {
  const icons = useIcons();
  const flags = useFlags();
  const cities = pageContext.airtableCities.map(mapLocation);
  const states = pageContext.airtableStates.map(mapLocation);
  const expiringContracts = getNextExpiringContracts(cities, 4);
  const statesWithBillOfRights = states.filter(
    state => state.hasPoliceBillOfRights
  );

  const handleCityChange: React.FormEventHandler<HTMLSelectElement> = event => {
    const cityIndex = Number(event.currentTarget.value);
    const city = cities[cityIndex];

    navigate(
      createCityUrl({
        name: city.name,
        state: city.containedBy[0],
      })
    );
  };

  return (
    <>
      <SEO
        title=""
        ogdescription="Stop police unions from rigging the system. Take action now to help #NixThe6!"
        twitterdescription="Stop police unions from rigging the system in favor of officers who commit misconduct. Take action now to help #NixThe6!"
        description="Police unions prevent officers from being held accountable for police brutality and misconduct. Learn how to fight the police unions in your city and win."
      />
      <Layout>
        <IndexContainer>
          <section className="the-six">
            <Link to="/no-more" aria-label="Read the details">
              <ol>
                <li>
                  <NoMore />
                  <div className="balanced">
                    Police Union Contracts Blocking Accountability
                  </div>
                </li>
                <li>
                  <NoMore />
                  <div className="balanced">
                    Rehiring Officers Fired for Misconduct
                  </div>
                </li>
                <li>
                  <NoMore />
                  <div className="balanced">
                    Police Bill of<br></br>Rights Laws
                  </div>
                </li>
                <li>
                  <NoMore />
                  <div className="balanced">
                    Police Union Influence Over Police Budgets
                  </div>
                </li>
                <li>
                  <NoMore />{' '}
                  <div className="balanced">
                    Police Unions Buying Political Power
                  </div>
                </li>
                <li>
                  <NoMore />{' '}
                  <div className="balanced">
                    Negotiations without Community Representation
                  </div>
                </li>
              </ol>
            </Link>
          </section>

          <section className="cities-and-states">
            <div className="container">
              <p className="intro balance-text">
                Across the country, police unions have written contracts and
                laws that make it almost impossible to hold police accountable.
                We reviewed police union contracts in nearly{' '}
                <strong>600 cities</strong> and &ldquo;Police Bill of
                Rights&rdquo; laws in{' '}
                <strong>{statesWithBillOfRights.length} states</strong>.
              </p>

              {flags.hide.has('city_pages') || (
                <>
                  <h3 className="balance-text">
                    See how police unions have rigged the system in your
                    community:
                  </h3>
                  <label htmlFor="find-your-city" className="visually-hidden">
                    Select your City
                  </label>
                  <select
                    defaultValue={-1}
                    onChange={handleCityChange}
                    id="find-your-city"
                  >
                    <option key={-1} value={-1} disabled>
                      Find Your City
                    </option>
                    {cities.map((city, i) => (
                      <option key={i} value={i}>
                        {city.name}, {city.containedBy[0]?.name}
                      </option>
                    ))}
                  </select>
                </>
              )}

              <h3 className="balance-text">
                These <strong>{statesWithBillOfRights.length} states</strong>{' '}
                have &ldquo;Police Bill of Rights&rdquo; laws granting special
                protections to officers who commit misconduct:
              </h3>

              <ul className="states">
                {statesWithBillOfRights.map(state => {
                  return flags.hide.has('state_pages') === false ? (
                    <li key={state.shortName}>
                      <Link to={createStateUrl(state)}>
                        <span className="name">{state.name}</span>
                        {state.image && <img src={state.image.url} alt="" />}
                        {state.repealed && <div className="repealed"></div>}
                      </Link>
                    </li>
                  ) : (
                    <li key={state.shortName}>
                      <Link to={'#'} style={{ cursor: 'default' }}>
                        <span className="name">{state.name}</span>
                        {state.image && <img src={state.image.url} alt="" />}
                      </Link>
                    </li>
                  );
                })}
              </ul>

              <div className="contributions">
                <h3 className="balance-text">
                  FIND ELECTED OFFICIALS IN YOUR STATE WHO HAVE RECEIVED
                  DONATIONS FROM POLICE & CORRECTIONS UNIONS OR PRIVATE PRISON
                  LOBBYISTS:
                </h3>
                <TakeActionLink className="dark" href="/contributions">
                  <span>View Contributions Map</span>
                  <SVG
                    src={icons.link.publicURL}
                    width="24"
                    height="24"
                    className="icon"
                  />
                </TakeActionLink>
              </div>

              {expiringContracts.length > 0 && (
                <>
                  <h3 className="expire-soon">
                    <strong>Cities Negotiating Contracts</strong>
                  </h3>
                  <h3 className="balance-text">
                    Hundreds of cities negotiate new police union contracts each
                    year. Here are some cities currently negotiating:
                  </h3>
                  <ul className="expiring-contracts">
                    {expiringContracts.map((contract, i) => {
                      const city = contract.location;
                      const expirationDate = contract.expirationDate;

                      return (
                        <li key={i}>
                          <Link
                            to={createCityUrl({
                              name: city.name,
                              state: {
                                name: city.containedBy[0].name,
                                code:
                                  city.containedBy[0].shortName ||
                                  city.containedBy[0].name,
                              },
                            })}
                            className="card"
                          >
                            <div className="item-top">
                              <p className="expiration">
                                Expires: <ShortDate theDate={expirationDate} />
                              </p>
                              <h4 className="balance-text">
                                {city.name}, {city.containedBy[0]?.shortName}
                              </h4>
                              <ul className="ul-icon li-close">
                                {contract.issues.map(issue => (
                                  <li key={issue.type.name}>
                                    {issue.type.name}
                                  </li>
                                ))}
                              </ul>
                            </div>
                            {flags.hide.has('city_pages') || (
                              <div className="take-action-cta">Take Action</div>
                            )}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </>
              )}
            </div>
          </section>

          <section className="six-ways">
            <h2>6 Ways Police Union Contracts Block Accountability</h2>
            <Link
              to="/6-ways-police-union-contracts-block-accountability/"
              className="grid-link"
              aria-label="Read the policy details"
            >
              <ol>
                <li>Destroying Records Of Police Misconduct</li>
                <li>Tossing Out Misconduct Complaints</li>
                <li>Delaying Or Restricting Interrogations Of Officers</li>
                <li>Giving Officers Preferential Access To Evidence</li>
                <li>Restricting Disciplinary Consequences</li>
                <li>Requiring Communities Fund Misconduct</li>
              </ol>
            </Link>
            <Link className="research-basis-cta" to="/research-basis/">
              Research Basis
            </Link>
          </section>

          <section className="facts">
            <div className="card">
              <img
                src={icons.destroyed.publicURL}
                alt="Icon of a paper shredder"
              />
              <div className="card-body">
                In some cities, police union contracts require misconduct
                records <strong>to be destroyed.</strong>
              </div>
            </div>
            <div className="card">
              <img src={icons.piechart.publicURL} alt="Pie Chart" height="96" />
              <div className="card-body">
                Police unions make it{' '}
                <strong>harder for cities to cut police funding</strong> or
                change the size of the force. Over 80% of the average police
                budget is comprised of personnel costs that are negotiated
                within police union contracts.
              </div>
            </div>
            <div className="card">
              <img
                src={icons.injustice.publicURL}
                alt="Hand on scales of justice"
              />
              <div className="card-body">
                In some cities,{' '}
                <strong>contracts enable officers to appeal discipline</strong>{' '}
                or termination to a lawyer, called an arbitrator, who the police
                union usually helps select.
              </div>
            </div>
            <div className="card">
              <img
                src={icons.influence.publicURL}
                alt="Icon of government building"
              />
              <div className="card-body">
                Police unions spend millions each year to influence politicians
                — blocking efforts to hold the police accountable and{' '}
                <strong>rigging the system</strong> in favor of officers who
                commit misconduct.
              </div>
            </div>
          </section>
        </IndexContainer>
      </Layout>
      <TextBalancer />
    </>
  );
};

const getNextExpiringContracts = (
  locations: Location[],
  max: number
): Contract[] => {
  const now = Date.now();

  // For the time being, we are hard-coding the featured contracts.
  return (
    locations
      .reduce(
        (allContracts, location) => [
          ...allContracts,
          ...location.contracts
            .filter(contract => {
              return contract.id
                ? FEATURED_CONTRACTS.includes(contract.id)
                : false;
              // const exp = contract.expirationDate;
              // return exp !== undefined && exp.valueOf() > now;
            })
            // .sort(sortContractsByExpirationDate)
            .slice(0, 1),
        ],
        [] as Contract[]
      )
      // .sort(sortContractsByExpirationDate)
      .sort((a, b) => {
        return (
          FEATURED_CONTRACTS.indexOf(a.id as string) -
          FEATURED_CONTRACTS.indexOf(b.id as string)
        );
      })
      .slice(0, max)
  );
};

const sortContractsByExpirationDate = (a: Contract, b: Contract): number => {
  return (
    (a.expirationDate?.valueOf() || 0) - (b.expirationDate?.valueOf() || 0)
  );
};

export const NoMore = () => {
  return <div className="no-more">No more</div>;
};

export const IndexContainer = styled.div`
  .the-six {
    max-width: 830px;
    margin: 0 auto 70px auto;

    > a {
      display: block;
      text-decoration: none;

      &:hover {
        .balanced {
          color: ${constants.highlightColor};
        }
      }
    }

    ol {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      counter-reset: i;

      @media (max-width: 767.98px) {
        padding: 0 25px;
      }

      li {
        text-align: center;
        flex: 0 0 33%;
        font-weight: bold;
        position: relative;
        align-items: baseline;
        margin-bottom: 20px;
        padding: 0 0.5rem;

        @media (max-width: 767.98px) {
          flex: 0 0 50%;
        }

        &::before {
          counter-increment: i;
          content: counter(i);
          text-transform: uppercase;
          display: block;
          font-size: 70px;
          font-family: VTCMarsha, ${constants.primaryFontFamily};
          font-weight: 700;
          line-height: 1;
        }

        &::after {
          content: '';
          display: block;
          height: 10px;
          width: 80px;
          height: 6px;
          background-color: ${constants.highlightColor};
          position: absolute;
          top: 30px;
          left: 50%;
          transform: translateX(-50%);
        }

        .no-more {
          text-transform: uppercase;
          display: block;
          color: ${constants.highlightColor};
          margin: 0.5em 0;
        }

        .balanced {
          font-size: 16px;
          letter-spacing: 0;
          text-align: center;
          line-height: 22px;
          text-align: center;
          margin: 0 auto;
          max-width: 210px;
          transition: ${constants.colorTransition};
        }
      }
    }
  }

  .cities-and-states {
    background-color: ${constants.highlightColor};
    color: ${constants.highlightForegroundColor};
    padding: 80px 1rem;

    @media (max-width: 767.98px) {
      padding: 48px 18px;
    }

    .intro {
      font-size: 24px;
      line-height: 1.5;
      margin: 0 auto;
      color: #231f20;
      text-align: center;

      @media (max-width: 767.98px) {
        font-size: 18px;
        letter-spacing: 0;
        text-align: center;
        line-height: 22px;
      }
    }

    h3 {
      font-size: 18px;
      line-height: 28px;
      font-weight: normal;
      text-transform: uppercase;
      text-align: center;
      margin: 1.5em auto;
      color: #181616;

      @media (max-width: 767.98px) {
        font-size: 14px;
        letter-spacing: 0;
        text-align: center;
        line-height: 18px;
      }
    }

    select {
      font-size: 24px;
      font-weight: bold;
      display: block;
      margin: 0 auto;
      width: 100%;
      max-width: 800px;
      padding: 0.4em 0.6em;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;
      margin-bottom: 3em;
      width: 100%;
      line-height: 1.5;
      color: ${constants.highlightForegroundColor};
      vertical-align: middle;
      background: #fff
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
        no-repeat right 0.75rem center/8px 10px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    .states {
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      list-style: none;
      max-width: 1120px;
      margin: 0 auto;

      li {
        text-align: center;
        flex: 0 0 16%;
        position: relative;

        @media (max-width: 767.98px) {
          flex: 0 0 32%;
        }

        a {
          font-size: 21px;
          font-weight: bold;
          text-decoration: none;
          padding: 35px 0;
          margin: 20px;
          display: inline-block;
          position: relative;
          transition: ${constants.colorTransition};
          color: #210000;

          &:hover {
            color: #fff;
          }

          .name {
            position: relative;
            z-index: 2;
            font-size: 21px;
            letter-spacing: 0;
            text-align: center;

            @media (max-width: 767.98px) {
              font-size: 14px;
            }
          }

          img {
            position: absolute;
            top: 50%;
            left: 50%;
            opacity: 0.5;
            transform: translateX(-50%) translateY(-50%);
          }
        }
        .repealed {
          position: absolute;
          top: 50%;
          left: -20px;
          z-index: 3;
          width: calc(100% + 40px);
          height: 3px;
          background: #fa0000;
        }
      }
    }

    .contributions {
      text-align: center;
      margin-bottom: 3rem;
    }

    h3.expire-soon {
      font-size: 18px;
      color: #181616;
      letter-spacing: 0;
      text-align: center;
      line-height: 28px;
    }

    .expiring-contracts {
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: stretch;
      list-style: none;
      max-width: 1184px;
      margin: 0 auto;

      & > li {
        flex: 0 0 25%;
        padding: 0 8px 20px;

        @media (min-width: 992px) {
          &:first-of-type {
            padding-left: 0;
          }

          &:last-of-type {
            padding-right: 0;
          }
        }

        @media (max-width: 991.98px) {
          flex: 0 0 50%;
        }

        @media (max-width: 767.98px) {
          flex: 0 0 100%;
          padding: 0 0 20px;

          &:last-of-type {
            padding-bottom: 0;
          }
        }

        .card {
          display: flex;
          height: 100%;
          align-items: flex-start;
          flex-direction: column;
          background-color: rgba(255, 255, 255, 0.7);
          list-style: none;
          padding: 25px 11px 13px 11px;
          position: relative;
          border-radius: 8px;
          transition: background-color 0.15s linear;
          text-decoration: none;

          &:hover {
            background-color: rgba(255, 255, 255, 1);

            .take-action-cta {
              color: #fff;
              background-color: ${constants.highlightColor};
            }
          }
        }

        .item-top {
        width: 100%;
        }

        .take-action-cta {
          margin-top: auto;
          transition: all 0.1.5s linear;
          display: block;
          width: 100%;
          background-color: ${constants.highlightForegroundColor};
          color: ${constants.highlightColor};
          padding: 1em;
          font-size: 15px;
          font-weight: bold;
          text-align: center;
          text-transform: uppercase;
          text-decoration: none;
          border-radius: 4px;
          transition: all linear 150ms;
        }

        .expiration {
          font-size: 15px;
          color: #fa0000;
          letter-spacing: 0.5px;
          line-height: 18px;
          font-weight: bold;
          text-transform: uppercase;
          text-align: center;
        }

        h4 {
          font-weight: bold;
          margin: 0.5em auto 24px;
          font-size: 24px;
          color: #181616;
          letter-spacing: 0;
          line-height: 28px;
          text-align: center;
        }

        ul {
          li {
            padding-left: 0.25em;
            margin-left: 1.3em;
            margin-bottom: 1em;
          }
        }

        ul.li-close {
          margin-bottom: 29px;

          > li {
            background-size: 17px 17px;
            margin-left: 0 !important;
            font-size: 15px;
            color: #181616;
            letter-spacing: 0;
            line-height: 18px;
            padding: 5px 0 5px 29px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .six-ways {
    padding: 60px 20px 80px;
    max-width: 844px;
    margin: 0 auto;

    @media (max-width: 767.98px) {
      background: ${constants.highlightForegroundColor};
    }

    h2 {
      font-size: 36px;
      line-height: 42px;
      text-align: center;
      margin-bottom: 1em;
      text-transform: uppercase;

      @media (max-width: 767.98px) {
        font-size: 18px;
        letter-spacing: 0;
        line-height: 22px;
      }
    }

    .grid-link {
      text-decoration: none;
      transition: ${constants.colorTransition};

      &:hover {
        color: ${constants.highlightColor};

        &::before {
          color: #fff;
        }
      }
    }

    ol {
      padding: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      counter-reset: i;

      li {
        text-align: center;
        flex: 0 0 33%;
        font-weight: bold;
        line-height: 22px;
        position: relative;
        align-items: baseline;
        padding: 0 16px 16px 16px;
        letter-spacing: 0;

        @media (max-width: 767.98px) {
          flex: 0 0 50%;
          font-size: 13px;
          letter-spacing: 0;
          text-align: center;
          line-height: 16px;
        }

        &::before {
          counter-increment: i;
          content: counter(i);
          text-transform: uppercase;
          display: block;
          font-size: 40px;
          font-weight: bold;
          border-radius: 50%;
          font-family: VTCMarsha, sans-serif;
          border: 5px solid ${constants.highlightColor};
          height: 60px;
          width: 60px;
          line-height: 65px;
          margin: 0 auto 10px auto;
          color: #fff;
        }
      }
    }

    .research-basis-cta {
      padding: 1.25em;
      font-size: 18px;
      font-weight: bold;
      text-decoration: none;
      display: block;
      max-width: 280px;
      text-align: center;
      background-color: ${constants.highlightColor};
      color: ${constants.highlightForegroundColor};
      text-transform: uppercase;
      border-radius: 8px;
      margin: 2em auto;
      transition: color linear 150ms;

      &:hover {
        color: #fff;
      }
    }
  }

  .facts {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    max-width: 1184px;
    margin: 0 auto;
    padding: 0 25px;

    .card {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 20px;
      padding: 0 32px;

      &:last-of-type {
        margin-bottom: 0;
      }

      .card-body {
        font-size: 18px;
        letter-spacing: 0;
        line-height: 28px;
      }

      @media (max-width: 767.98px) {
        display: flex;
        width: 100%;
        padding: 0;

        img {
          margin-right: 28px !important;
          width: 65px;
        }

        .card-body {
          font-size: 13px;
          line-height: 19px;
        }
      }

      @media (min-width: 768px) {
        flex: 0 0 50%;
      }

      @media (min-width: 992px) {
        flex: 0 0 25%;

        &:first-of-type {
          padding-left: 0;
        }
        &:last-of-type {
          padding-right: 0;
        }
      }

      img {
        display: block;
        margin: 0 auto 20px auto;
      }
    }
  }
`;

export default HomePage;
