// This is plain Javascript so it can be used in gatsby-*.js files.

const cleanUrlPart = part => {
  return part
    .toLowerCase()
    .replace(/ +/g, '-')
    .replace(/[^A-Za-z]/g, '');
};

exports.createCityUrl = city => {
  return '/' + [city.state.name, city.name].map(cleanUrlPart).join('/');
};

exports.createStateUrl = state => {
  return '/' + cleanUrlPart(state.name);
};
